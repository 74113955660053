import Vue from 'vue'
import VueRouter from 'vue-router'
import Default from '@/layouts/Default'
import Auth from '@/layouts/Auth'
import Daily from '@/views/Daily'
import Contracts from '@/views/Contracts'
import Queries from '@/views/Queries'
import Add from '@/components/contracts/Add'
// import AddQuery from '@/components/queries/Add'
import Edit from '@/components/contracts/Edit'
import EditQuery from '@/components/queries/Edit'
import Grid from '@/components/contracts/Grid'
import DailyGrid from '@/components/daily/Grid'
import QueryGrid from '@/components/queries/Grid'
import Details from '@/components/contracts/Details'
import Audit from '@/views/Audit'
import AuditIndex from '@/components/audit/Index'
import Archive from '@/views/Archive'
import ArchiveIndex from '@/components/archive/Index'
import Users from '@/views/Users'
import UsersIndex from '@/components/users/Index'
import Login from '@/views/Login'
// import Register from '@/views/Register'
import authService from '../services/auth.service'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Default',
    component: Default,
    children: [
      {
        path: 'daily',
        meta: {
          requiresAuth: true,
        },
        component: Daily,
        children: [
          {
            path: 'add',
            component: Add
          },
          {
            path: ':id',
            name: 'ContractDetails',
            component: Edit,
            props: (route) => {
              const id = Number.parseInt(route.params.id, 10)
              if (Number.isNaN(id)) {
                return 0
              }
              return { id }
            }
          },
          {
            path: '',
            component: DailyGrid
          }
        ]
      },
      {
        path: 'contracts',
        meta: {
          requiresAuth: true,
        },
        component: Contracts,
        children: [
          {
            path: 'add',
            component: Add
          },
          {
            path: ':id',
            name: 'ContractDetails',
            component: Edit,
            props: (route) => {
              const id = Number.parseInt(route.params.id, 10)
              if (Number.isNaN(id)) {
                return 0
              }
              return { id }
            }
          },
          {
            path: '',
            component: Grid
          }
        ]
      },
      {
        path: 'queries',
        meta: {
          requiresAuth: true,
        },
        component: Queries,
        children: [
          {
            path: 'add',
            component: Add
          },
          {
            path: ':id',
            name: 'QueryDetails',
            component: EditQuery,
            props: (route) => {
              const id = Number.parseInt(route.params.id, 10)
              if (Number.isNaN(id)) {
                return 0
              }
              return { id }
            }
          },
          {
            path: '',
            component: QueryGrid
          }
        ]
      },
      {
        path: 'archive',
        meta: {
          requiresAuth: true,
        },
        component: Archive,
        children: [
          {
            path: '',
            component: ArchiveIndex
          },
          {
            path: ':id',
            name: 'ArchiveDetails',
            component: Details,
            props: (route) => {
              const id = Number.parseInt(route.params.id, 10)
              if (Number.isNaN(id)) {
                return 0
              }
              return { id }
            }
          },
        ]
      },
      {
        path: 'audit',
        meta: {
          requiresAuth: true,
        },
        component: Audit,
        children: [
          {
            path: '',
            component: AuditIndex
          }
        ]
      },
      {
        path: '/users',
        meta: {
          requiresAuth: true,
        },
        component: Users,
        children: [
          {
            path: '',
            component: UsersIndex
          }
        ]
      },
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login
      },
      // {
      //   path: 'register',
      //   name: 'register',
      //   component: Register
      // }
    ]
  },
  {
    path: '/login',
    redirect: '/auth/login',
  },
  // {
  //   path: '/contracts',
  //   redirect: '/Default/contracts',
  // },
  // {
  //   path: '/register',
  //   redirect: '/auth/register',
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(r => r.meta.requiresAuth)) {
    if(!authService.isLoggedIn()) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.push({path: '/contracts' })
export default router
