<template>
    <div class="relative">
        <ConfirmDialog></ConfirmDialog>

        <Header>Ugovori</Header>

        <div class="actions p-d-flex p-jc-between p-p-2">
            <SearchFilter :filter="filter" placeholder="Ime jahte, šifra ugovora, email..." @search="filterSearch" @reset="reset" />
            <router-link to='/contracts/add'>
                <Button icon="pi pi-plus" label="Dodaj novi ugovor" class="p-button-sm" />
            </router-link>
        </div>
        
        <DataTable
            scrollHeight="flex" 
            :value="contracts"
            :lazy="true"
            :rows="params.rows"
            :paginator="true"
            :autoLayout="true"
            ref="contracts"
            :totalRecords="contractMeta.totalCount"
            :loading="loading"
            class="p-datatable-sm"
            selectionMode="single"
            dataKey="id"
            @page="onPage($event)"
            @sort="onSort($event)"
            @row-select="onRowSelect"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="Prikaz {first} do {last} od {totalRecords} ugovora"
            @row-unselect="onRowUnselect">
            <template #empty>
                <div class="p-p-3">
                    Nije pronađen nijedan ugovor.
                </div>
            </template>
            <Column>
                <template #body="slotProps">
                    <warning-block :contract="slotProps.data" />
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <comments-icon :comments="slotProps.data.comments" />
                </template>
            </Column>
            <Column header="Datum ugovora">
                <template #body="slotProps">
                    <display-date :date="slotProps.data.dateSigned" />
                </template>
            </Column>
            <Column header="Datum od - do">
                <template #body="slotProps">
                    <div class="p-d-flex">
                        <span class="p-mr-2"><display-date :date="slotProps.data.dateFrom" /></span> -
                        <span class="p-ml-2"><display-date :date="slotProps.data.dateTo" /></span>
                    </div>
                </template>
            </Column>
            <Column field="code" header="Šifra ugovora"></Column>
            <Column header="Ime broda/jahte">
                <template #body="slotProps">
                    <div class="p-d-flex p-flex-column">
                        {{ slotProps.data.yacht }}
                        <small class="p-mt-1 p-text-gray">{{ slotProps.data.registration }}, {{ slotProps.data.length }} m</small>
                    </div>
                </template>
            </Column>
            <Column header="Vlasnik">
                <template #body="slotProps">
                    <div class="p-d-flex p-flex-column">
                        {{ slotProps.data.owner }}
                        <small class="p-mt-1 p-text-gray">{{ slotProps.data.ownerContact }}</small>
                    </div>
                </template>
            </Column>
            <!-- <Column header="Puna cijena">
                <template #body="slotProps">
                    {{ slotProps.data.price }} €
                </template>
            </Column> -->
            <Column header="Uplaćeno">
                <template #body="slotProps">
                    <price-display :contract="slotProps.data" />
                </template>
            </Column>
            <Column header="Za uplatiti">
                <template #body="slotProps">
                    <price-left :contract="slotProps.data" />
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <price-progress :data="slotProps.data" />
                </template>
            </Column>
            <Column header="# uplata">
                <template #body="slotProps">
                    <payments-icon :payments="slotProps.data.payments" />
                </template>
            </Column>
            <Column headerStyle="width: 8em" bodyStyle="text-align: center">
                <template #body="slotProps">
                    <Button icon="pi pi-book" class="p-button-secondary p-button-text" @click="archive(slotProps.data)" :disabled="!canArchive(slotProps.data)" v-tooltip.left="'Arhiviraj ugovor'" />
                    <Button icon="pi pi-trash" class="p-button-secondary p-button-text" @click="deleteItem(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import WarningBlock from '../common/WarningBlock.vue'
import PaymentsIcon from '../common/PaymentsIcon.vue'
import CommentsIcon from '../common/CommentsIcon.vue'
import PriceProgress from '../common/PriceProgress.vue'
import DisplayDate from '../common/DisplayDate.vue'
import PriceDisplay from '../common/PriceDisplay.vue'
import PriceLeft from '../common/PriceLeft.vue'
import SearchFilter from '@/components/common/Filter'
import Header from '@/components/common/Header'
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
export default {   
    components: {
        Header,
        SearchFilter,
        DisplayDate,
        PriceProgress,
        CommentsIcon,
        PaymentsIcon,
        WarningBlock,
        PriceDisplay,
        PriceLeft
    },
    data() {
        return {
            loading: true,
            filter: {},
            params: {
                first: 0,
                page: 1,
                rows: 20,                
                sortField: null,
                sortOrder: null,
                filters: null
            },
        }
    },
    computed: {
        ...mapGetters({
            contracts: 'contracts',
            contractMeta: 'contractMeta',
        })
    }, 
    async created() {
        await this.search()
    },
    methods: {  
        canArchive(contract) {
            return contract.paymentSum === contract.price
        },
        ...mapActions({
            getContracts: 'GETCONTRACTS',            
            archiveContract: 'ARCHIVECONTRACT',
            getPaginatedContracts: 'GETPAGINATEDCONTRACTS',
            deleteContract: "DELETECONTRACT"
        }),
        onRowSelect(event) {
            router.push({ path: `/contracts/${event.data.id}` })
        },
        onRowUnselect(event) {
            console.log(event)
        },
        async deleteItem(contract) {          
            this.$confirm.require({
                message: 'Sigurno želite obrisati ugovor?',
                header: 'Brisanje ugovora',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    this.loading = true
                    await this.deleteContract(contract)
                    await this.getContracts({ params: this.params, filter: this.filter })
                    this.loading = false
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            })
        },        
        async archive(contract) {          
            this.$confirm.require({
                message: 'Sigurno želite arhivirati ugovor?',
                header: 'Arhiviranje ugovora',
                icon: 'pi pi-book',
                accept: async () => {
                    this.loading = true
                    contract.archived = 1
                    await this.archiveContract(contract)   
                    await this.getContracts({ params: this.params, filter: this.filter })
                    this.loading = false
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            })
        },
        async filterSearch() {
            await this.search()
        },
        async reset() {
            this.filter = {}
            await this.search()
        },
        async search() {
            this.loading = true
            await this.getContracts({ params: this.params, filter: this.filter })
            this.loading = false
        },
        async onPage(event) {
            this.params = event
            this.params.page = event.page + 1
            await this.search()
        },
    },
}
</script>