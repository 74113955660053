<template>
    <div class="filter p-d-flex p-ai-center">
        <InputText style="width:200px;" type="text" v-model.trim="filter.all" @keydown.enter="$emit('search', filter)" class="p-mr-2 p-inputtext-sm" :placeholder="placeholder" />

        <div class="p-buttonset">
            <Button label="Pretraži" icon="pi pi-search" class="p-button-sm" @click="$emit('search', filter)" />
            <Button label="Poništi" icon="pi pi-times-circle" class="p-button-secondary p-button-sm" @click="$emit('reset')" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        filter: {
            type: Object,
            required: true,
        },
        placeholder: {
            type: String,
            required: true,
        }
    },
}
</script>