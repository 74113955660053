import Vue from 'vue'
import Vuelidate from 'vuelidate'
import store from './state/store'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputNumber  from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Textarea from 'primevue/textarea'
import Dialog from 'primevue/dialog'
import Calendar from 'primevue/calendar'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Skeleton from 'primevue/skeleton'
import ScrollPanel from 'primevue/scrollpanel'
import ProgressBar from 'primevue/progressbar'
import ProgressSpinner from 'primevue/progressspinner'
import BlockUI from './components/common/Block'
import SelectButton from 'primevue/selectbutton'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmationService from 'primevue/confirmationservice'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import Password from 'primevue/password'
import Checkbox from 'primevue/checkbox'
import OverlayPanel from 'primevue/overlaypanel'
import Tooltip from 'primevue/tooltip'
import Knob from 'primevue/knob'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import InputSwitch from 'primevue/inputswitch'
import Divider from 'primevue/divider'
import Tag from 'primevue/tag'
import Message from 'primevue/message'

import '../public/css/normalize.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import '../public/css/style.css'

Vue.config.productionTip = false

Vue.use(PrimeVue)
Vue.use(Vuelidate)
Vue.use(ConfirmationService)
Vue.use(ToastService)
Vue.directive('tooltip', Tooltip)
Vue.component('Knob', Knob)
Vue.component('Toast', Toast)
Vue.component('InputSwitch', InputSwitch)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('Tag', Tag)
Vue.component('Divider', Divider)
Vue.component('Message', Message)
Vue.component('Checkbox', Checkbox)
Vue.component('Password', Password)
Vue.component('Menubar', Menubar)
Vue.component('Menu', Menu)
Vue.component('ConfirmDialog', ConfirmDialog)
Vue.component('SelectButton', SelectButton)
Vue.component('BlockUI', BlockUI)
Vue.component('Dropdown', Dropdown)
Vue.component('ProgressSpinner', ProgressSpinner)
Vue.component('ProgressBar', ProgressBar)
Vue.component('ScrollPanel', ScrollPanel)
Vue.component('Skeleton', Skeleton)
Vue.component('Splitter', Splitter)
Vue.component('SplitterPanel', SplitterPanel)
Vue.component('Calendar', Calendar)
Vue.component('Textarea', Textarea)
Vue.component('InputText', InputText)
Vue.component('InputNumber', InputNumber)
Vue.component('Dialog', Dialog)
Vue.component('Button', Button)
Vue.component('DataTable', DataTable)
Vue.component('Column', Column)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
