const contractsHelper = {
    CalculatePercentPaid(contract) {
        let totalPaid = 0
        let paymentSum = 0
        let discountedPrice = contract.price - (contract.price * contract.discount / 100)
    
        if(contract.payments.length) {
            contract.payments.filter(x => x.isPaid === 1).forEach(p => {
                paymentSum += parseFloat(p.amount)
            })
            
            totalPaid =  (paymentSum / parseFloat(discountedPrice)) * 100
        }
    
        contract.paymentSum = parseFloat(paymentSum)
        contract.percentPaid = parseFloat(totalPaid.toFixed(2))
    }
}

export default contractsHelper;