<template>
    <div class="relative">
        <Header>Dnevni vezovi</Header>

        <DataTable
            scrollHeight="flex" 
            :value="dailies"
            :lazy="true"
            :rows="params.rows"
            :paginator="true"
            :autoLayout="true"
            ref="dailies"
            :totalRecords="dailyMeta.totalCount"
            :loading="loading"
            class="p-datatable-sm"
            selectionMode="single"
            dataKey="id"
            @page="onPage($event)"
            @sort="onSort($event)"
            @row-select="onRowSelect"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="Prikaz {first} do {last} od {totalRecords} dnevnih vezova"
            @row-unselect="onRowUnselect">
            <template #empty>
                <div class="p-p-3">
                    Nije pronađen nijedan dnevni vez.
                </div>
            </template>
            <Column header="Datum ugovora">
                <template #body="slotProps">
                    <display-date :date="slotProps.data.dateFrom" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import Header from '@/components/common/Header'
import DisplayDate from '../common/DisplayDate.vue'
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
export default {
    components: {
        Header,
        DisplayDate
    },
    data() {
        return {
            loading: true,
            filter: {},
            params: {
                first: 0,
                page: 1,
                rows: 20,                
                sortField: null,
                sortOrder: null,
                filters: null
            }
        }
    },    
    computed: {
        ...mapGetters({
            dailies: 'dailies',
            dailyMeta: 'dailyMeta',
        })
    }, 
    async created() {
        await this.search()
    },
    methods: {
        ...mapActions({
            getDailys: 'GETDAILYS',
        }),
        async search() {
            this.loading = true
            await this.getDailys({ params: this.params, filter: this.filter })
            this.loading = false
        },
        async onPage(event) {
            this.params = event
            this.params.page = event.page + 1
            await this.search()
        },
        onRowSelect(event) {
            router.push({ path: `/contracts/${event.data.id}` })
        },
        onRowUnselect(event) {
            console.log(event)
        },
    }
}
</script>