import httpClient from "./http.service";

const paymentsService = {
    get() {
        return httpClient.get('payments')
    },
    create(payment) {
        return httpClient.post('payments', payment)
    },
    update(payment) {
        return httpClient.put(`payments/${payment.id}`, payment)
    },
    delete(paymentId) {
        return httpClient.delete(`payments/${paymentId}`)
    }
}

export default paymentsService;