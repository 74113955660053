
import { Action, Mutation } from './types'
import dailyService from '../services/daily.service'

export default {
    state: () => ({
        dailies: [],
        dailyMeta: {},
    }),
    actions: {
        async [Action.GETDAILYS]({ commit }, params) {
            // commit(Mutation.SETLOADER, true)
            const { data } = await dailyService.get(params)
            commit(Mutation.SETDAILYS, data.items)
            commit(Mutation.SETDAILYMETA, data._meta)
            // commit(Mutation.SETLOADER, false)
        },     
    },
    mutations: {
        [Mutation.SETDAILYS](state, queries) {
            state.dailies = queries
        },
        [Mutation.SETDAILYMETA](state, meta) {
            state.dailyMeta = meta
        },
    },
    getters: {
        dailies: state => {
            return state.dailies
        },
        dailyMeta: state => {
            return state.dailyMeta
        },
    }
}