import httpClient from "./http.service"
// import { format } from 'date-fns'

// function modifyDate(date) {
//     return format(date, 'yyyy-MM-dd 00:00:00')
// }

const contractsService = {
    get(params, confirmed = true) {
        return httpClient.get(`contracts?page=${params.params.page}`, {
            params: {
                params: params,
                confirmed: confirmed
            }
        })
    },
    getArchived(params) {
        return httpClient.get(`contract/archived?page=${params.params.page}`, {
            params: {
                params: params,
            }
        })
    },
    getById(contractId) {
        return httpClient.get(`contracts/${contractId}`)
    },
    create(contract) {
        // contract.dateArrival = modifyDate(contract.dateArrival)
        // contract.dateSigned = modifyDate(contract.dateSigned)
        // contract.dateFrom = modifyDate(contract.dateFrom)
        // contract.dateTo = modifyDate(contract.dateTo)
        return httpClient.post('contracts', contract)
    },
    update(contract) {
        // contract.dateArrival = modifyDate(contract.dateArrival)
        // contract.dateSigned = modifyDate(contract.dateSigned)
        // contract.dateFrom = modifyDate(contract.dateFrom)
        // contract.dateTo = modifyDate(contract.dateTo)
        contract.price = Number(contract.price)
        return httpClient.put(`contracts/${contract.id}`, contract)
    },
    archive(contract) {
        return httpClient.put(`contract/archive?contractId=${contract.id}`)
    },
    confirm(contract) {
        return httpClient.put(`contract/confirm?contractId=${contract.id}`)
    },
    delete(contractId) {
        return httpClient.delete(`contracts/${contractId}`)
    },
    isCodeUnique(contract) {
        return httpClient.post(`contract/is-code-unique`, contract)
    }
}

export default contractsService;