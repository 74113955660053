<template>
    <div>
        <Calendar v-model="localDate" dateFormat="dd.mm.yy" :showButtonBar="true" :disabled="disabled" :showIcon="true" />
    </div>
</template>

<script>
import { parse, format } from 'date-fns'

export default {
    props: {
        date: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean
        }
    },
    data() {
        return {            
            localDate: this.date !== null ? parse(this.date, "yyyy-MM-dd 00:00:00", new Date()) : null
        }
    },
    watch: {
        localDate: function() {
            (this.localDate != "") ? this.$emit("update:date", format(this.localDate, "yyyy-MM-dd 00:00:00")) : this.$emit("update:date", null)
        }
    }
}
</script>