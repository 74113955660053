<template>
    <div class="navigation p-d-flex p-ai-center p-jc-between p-px-2 p-py-2">
        <svg viewBox="0 0 600 576" xmlns="http://www.w3.org/2000/svg">
            <path id="svg_31" d="m570.17701,286.459c-28.05,-7.001 -56.367,-13.823 -83.782,-20.423c-30.945,-7.469 -62.972,-15.186 -94.044,-23.028l-3.544,13.336c31.14,7.85 63.189,15.576 94.187,23.05c18.04,4.35 36.523,8.807 55.027,13.343l-156.711,0l105.698,14.62l-98.774,25.895l3.689,13.303l204.304,-53.602l-26.052,-6.495l0.002,0.001z" fill="#fff"/>
            <path id="svg_32" d="m270.18301,204.468l-149.644,-86.86l76.786,124.251l-193.552,50.668l24.968,6.747c48.686,13.144 140.202,37.237 169.291,44.887l-77.727,124.264l24.478,-14.181c41.003,-23.746 111.167,-58.732 111.863,-59.091l-6.444,-12.255c-0.586,0.292 -48.612,24.248 -88.652,46.066l85.422,-103.463l-30.21,10.158c-0.758,-0.195 -52.505,-14.247 -104.396,-28.216l106.33,-14.705l-161.121,0c0,0 -0.007,0 -0.017,-0.014l160.657,-42.069l32.92,14.882l-89.38,-107.948l101.179,58.714l7.248,-11.837l0.001,0.002z" fill="#fff"/>
            <path id="svg_33" d="m287.53101,292.967l-47.699,95.142l61.069,187.891l52.116,-174.972l131.826,71.54l-89.709,-137.777l-50.876,-41.837l51.349,-34.903l85.126,-139.417l-128.475,70.563l-51.501,-189.197l-59.17,203.911l46.216,89.057l-0.272,-0.001z" fill="#fff"/>
            <path id="svg_34" d="m441.52101,156.152l-108.089,98.244l23.231,-48.049l84.858,-50.195z" />
            <path id="svg_35" d="m356.66201,383.209l84.859,50.195l-108.089,-98.248l23.23,48.053z" />
            <path id="svg_36" d="m291.00801,83.114l-19.303,121.636l19.294,51.924l0.009,-173.56z" />
            <path id="svg_37" d="m271.61901,384.21l19.599,112.201l-0.431,-160.166l-19.168,47.965z" />
        </svg>

        <div class="p-d-flex p-ai-center">
            {{ user.username }}
            <i class="pi pi-ellipsis-v p-ml-2" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu"></i>
            <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
        </div>
    </div>
</template>

<script>
import authService from '@/services/auth.service'
export default {
    name: 'Nav',
    props: {
        user: Object,
    },
    data() {
        return {
            items: [
                {
                    label: 'Odjava',
                    icon: 'pi pi-sign-out',
                    command: () => {
                        this.logout()
                    }
                },
            ]
        }
    },
    methods: {
        logout() {
            authService.logout()
        },
        toggle(event) {
            this.$refs.menu.toggle(event);
        },
    }
}
</script>