export const Action = {
    GETPAGINATEDCONTRACTS: "GETPAGINATEDCONTRACTS",
    ADDCONTRACT: "ADDCONTRACT",
    GETCONTRACT: "GETCONTRACT",
    UPDATECONTRACT: "UPDATECONTRACT",
    DELETECONTRACT: "DELETECONTRACT",
    ADDPAYMENT: "ADDPAYMENT",
    DELETEPAYMENT: "DELETEPAYMENT",
    UPDATEPAYMENT: "UPDATEPAYMENT",
    ADDCOMMENT: "ADDCOMMENT",
    GETCONTRACTS: "GETCONTRACTS",
    ARCHIVECONTRACT: "ARCHIVECONTRACT",
    GETQUERIES: "GETQUERIES",
    CONFIRMCONTRACT: "CONFIRMCONTRACT",
    GETDAILYS: "GETDAILYS"
};

export const Mutation = {
    SETCONTRACTS: "SETCONTRACTS",
    SETCONTRACTCOUNT: "SETCONTRACTCOUNT",
    SETCONTRACTMETA: "SETCONTRACTMETA",
    ADDCONTRACT: "ADDCONTRACT",
    DELETECONTRACT: "DELETECONTRACT",
    REMOVEPAYMENT: "REMOVEPAYMENT",
    ADDCOMMENT: "ADDCOMMENT",
    SETQUERIES: "SETQUERIES",
    SETQUERYMETA: "SETQUERYMETA",
    SETDAILYS: "SETDAILYS",
    SETDAILYMETA: "SETDAILYMETA",
};