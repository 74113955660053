<template>
    <div class="p-d-flex p-flex-column">
        <strong>{{ leftToPay }} €</strong>
    </div>
</template>

<script>
export default {
    props: {
        contract: {
            type: Object
        },
    },
    computed: {
        leftToPay: function() {
            return this.discountedPrice - this.contract.paymentSum
        },
        discountAmount: function() {
            return this.contract.price * this.contract.discount / 100
        },
        discountedPrice: function() {
            return this.contract.price - this.discountAmount
        }
    }
}
</script>