<template>
    <div class="p-d-flex p-flex-column">
        {{ contract.paymentSum.toFixed(2) }} / {{ discountedPrice.toFixed(2) }} €
        <small class="p-mt-1 p-text-gray">Puna cijena: <strong>{{ contract.price }} €</strong>, popust: {{ contract.discount.toFixed(2) }}%</small>
        <!-- {{ discountedPrice.toFixed(2) }} € ({{ discount.toFixed(2) }}%)
        <small class="p-mt-1 p-text-gray">({{ price.toFixed(2) }} € - {{ discountAmount.toFixed(2) }} €)</small> -->
        <!-- {{ contract }} -->
    </div>
</template>

<script>
export default {
    props: {
        contract: {
            type: Object
        },
    },
    computed: {
        discountAmount: function() {
            return this.contract.price * this.contract.discount / 100
        },
        discountedPrice: function() {
            return this.contract.price - this.discountAmount
        }
    }
}
</script>