<template>    
    <div class="p-d-flex p-ai-center">
        <i class="pi pi-comments p-mr-2 p-text-gray"></i>
        {{ comments.length }}
    </div>
</template>

<script>
export default {
    props: {
        comments: {
            type: Array
        }
    }
}
</script>