<template>
    <div class="relative">
        <Header>
            Arhiva
        </Header>

        <div class="actions p-d-flex p-jc-between p-p-2">
            <SearchFilter :filter="filter" placeholder="Ime jahte, šifra ugovora, email..." @search="filterSearch" @reset="reset" />
        </div>

        <DataTable
            scrollHeight="flex" 
            :value="items.items"     
            :lazy="true"       
            :rows="items._meta.perPage"
            :paginator="true"
            :autoLayout="true"
            :totalRecords="items._meta.totalCount"
            :loading="loading"
            class="p-datatable-sm"
            ref="archive"            
            selectionMode="single"
            dataKey="id"
            @page="onPage($event)"
            @row-select="onRowSelect"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="Prikaz {first} do {last} od {totalRecords} ugovora">
            <template #empty>
                <div class="p-p-3">
                    Nije pronađen nijedan ugovor.
                </div>
            </template>
            <Column>
                <template #body="slotProps">
                    <comments-icon :comments="slotProps.data.comments" />
                </template>
            </Column>
            <Column header="Datum ugovora">
                <template #body="slotProps">
                    <display-date :date="slotProps.data.dateSigned" />
                </template>
            </Column>
            <Column header="Datum od">
                <template #body="slotProps">
                    <display-date :date="slotProps.data.dateFrom" />
                </template>
            </Column>
            <Column header="Datum do">
                <template #body="slotProps">
                    <display-date :date="slotProps.data.dateTo" />
                </template>
            </Column>
            <Column field="yacht" header="Ime broda/jahte"></Column>
            <Column field="code" header="Šifra"></Column>
            <Column header="Cijena">
                <template #body="slotProps">
                    {{ slotProps.data.price }} €
                </template>
            </Column>
            <Column header="# uplata">
                <template #body="slotProps">
                    <payments-icon :payments="slotProps.data.payments" />
                </template>
            </Column>
            <Column header="Uplaćeno">
                <template #body="slotProps">                    
                    <price-progress :data="slotProps.data" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import PaymentsIcon from '../common/PaymentsIcon.vue'
import CommentsIcon from '../common/CommentsIcon.vue'
import PriceProgress from '../common/PriceProgress.vue'
import DisplayDate from '../common/DisplayDate.vue'
import SearchFilter from '@/components/common/Filter'
import Header from '@/components/common/Header'
import contractService from '../../services/contracts.service'
import contractHelper from '../../services/contracts.helper'
import router from '@/router'
import { parse, format } from 'date-fns'
export default {
    components: {
        Header,
        SearchFilter,
        DisplayDate,
        PriceProgress,
        CommentsIcon,
        PaymentsIcon
    },    
    data() {
        return {
            items: {
                _meta: {
                    perPage: 20
                }
            },
            loading: true,
            filter: {},
            params: {
                first: 0,
                page: 1,
                rows: 20,                
                sortField: null,
                sortOrder: null,
                filters: null
            }
        }
    },
    async created() {
        await this.archived()
    },    
    methods: {
        parse: parse,
        format: format,
        onRowSelect(event) {
            router.push({ path: `/archive/${event.data.id}` })
        },
        async archived() {
            this.loading = true
            const { data } = await contractService.getArchived({ params: this.params, filter: this.filter })
            data.items.map(x => contractHelper.CalculatePercentPaid(x))
            this.items = data
            this.loading = false
        },
        async filterSearch() {
            await this.archived()
        },
        async reset() {
            this.filter = {}
            await this.archived()
        },
        async onPage(event) {
            this.params = event
            this.params.page = event.page + 1
            await this.archived()
        },
    }
}
</script>