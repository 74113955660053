<template>
    <div class="p-d-flex p-flex-column" v-if="authService.isLoggedIn()" style="height:100%; width:100%;">
        <Toast />
        <Nav :user="user" />
        
        <div class="p-d-flex" style="flex-grow:1; overflow: hidden">
            <div class="menu">                
                <Menu :model="items" />
            </div>

            <div class="main" style="overflow: auto;">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import authService from '@/services/auth.service'
import Nav from '@/components/layout/Nav'
export default {
    name: 'Default',
    components: {
        Nav
    },
    data() {
        return {
            user: {},
            authService: authService,
            items: [
                // {
                //     label: 'Dnevni vezovi',
                //     items: [
                //         {label: 'Dnevni vezovi', icon: 'pi pi-fw pi-folder-open', to: '/daily' },
                //     ]
                // }, 
                {
                    label: 'Upiti i ugovori',
                    items: [
                        {label: 'Upiti', icon: 'pi pi-fw pi-question-circle', to: '/queries' },
                        {label: 'Ugovori', icon: 'pi pi-fw pi-folder-open', to: '/contracts' },
                        {label: 'Arhiva', icon: 'pi pi-fw pi-book', to: '/archive' },
                    ]
                },                
                {
                    label: 'Audit',
                    items: [{label: 'Pregled', icon: 'pi pi-fw pi-info-circle', to: '/audit' }]
                },
                {
                    label: 'Administracija',
                    items: [{label: 'Korisnički računi', icon: 'pi pi-fw pi-users', to: '/users' }]
                },
            ]            
        }
    },
    async beforeMount() {
        this.user = await authService.getCurrentUser()
    },
}
</script>