<template>
    <div class="login">
        <form @submit.prevent="login">

            <div v-if="errors" class="errors">
                <label>Errors</label>
                <p v-for="(error, index) in errors" :key="index">
                    {{ error[0] }}
                </p>
            </div>

            <input type="text" v-model="form.username" placeholder="Username" />
            <Password v-model="form.password" toggleMask :feedback="false" placeholder="Password" />
            <button>Login</button>
        </form>
    </div>
</template>

<script>
import authService from '@/services/auth.service'
export default {
    name: 'Login',
    data() {
        return {
            form: {},
            errors: null
        }
    },
    methods: {
        async login() {
            const {success, errors} = await authService.login(this.form)
            if(success) {
                this.$router.push({ path: '/contracts' })
            } else {
                this.errors = errors
            }
        }
    }
}
</script>