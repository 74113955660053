// import Vue from 'vue'
import { Action, Mutation } from './types'
import contractsService from '../services/contracts.service'
import paymentsService from '@/services/payments.service'
import commentsService from '@/services/comments.service'
import contractsHelper from '@/services/contracts.helper'
// import router from '@/router'

export default {
    state: () => ({
        // loading: false,
        contracts: [],
        contractMeta: {},
        activeContract: {},
        queries: [],
        queryMeta: {}
        // mode: Mode.NONE,
    }),
    actions: {        
        async [Action.GETCONTRACTS]({ commit }, params) {
            // commit(Mutation.SETLOADER, true)
            const { data } = await contractsService.get(params)
            data.items.map(x => contractsHelper.CalculatePercentPaid(x))
            commit(Mutation.SETCONTRACTS, data.items)
            commit(Mutation.SETCONTRACTMETA, data._meta)
            // commit(Mutation.SETLOADER, false)
        },     
        async [Action.GETQUERIES]({ commit }, params) {
            const { data } = await contractsService.get(params, false)
            commit(Mutation.SETQUERIES, data.items)
            commit(Mutation.SETQUERYMETA, data._meta)
        },
        async [Action.GETPAGINATEDCONTRACTS]({ commit }, params) {
            // commit(Mutation.SETLOADER, true)
            const { data } = await contractsService.getPaginatedWithRelated(params)
            data.data.map(x => contractsHelper.CalculatePercentPaid(x))
            commit(Mutation.SETCONTRACTS, data.data)
            commit(Mutation.SETCONTRACTCOUNT, parseInt(data.pages.totalCount))
            // commit(Mutation.SETLOADER, false)
        },
        async [Action.ADDCONTRACT]({}, contract) {            
            const { status, data } = await contractsService.create(contract)
            if(status === 201) {
                return data
                // commit(Mutation.ADDCONTRACT, data)
            }
        },
        async [Action.GETCONTRACT]({}, contractId) {            
            const { status, data } = await contractsService.getById(contractId)
            if(status === 200) {                
                contractsHelper.CalculatePercentPaid(data)
                return data
            }
        },
        async [Action.UPDATECONTRACT]({}, contract) {
            const { status, data } = await contractsService.update(contract)
            if(status === 200) {        
                contractsHelper.CalculatePercentPaid(data)
                return data
                // commit(Mutation.UPDATECONTRACT, data)
                // commit(Mutation.SETACTIVECONTRACT, data)
            }
        },      
        async [Action.ARCHIVECONTRACT]({}, contract) {
            await contractsService.archive(contract)
        },         
        async [Action.CONFIRMCONTRACT]({}, contract) {
            await contractsService.confirm(contract)
        },        
        async [Action.DELETECONTRACT]({}, contract) {
            await contractsService.delete(contract.id)
            // commit(Mutation.REMOVEPAYMENT, payment)
        },
        async [Action.ADDPAYMENT]({ state }, payment) {
            const { status, data } = await paymentsService.create(payment)
            if(status === 201) {
                state.contracts.map(x => contractsHelper.CalculatePercentPaid(x))
                return data
                // commit(Mutation.ADDPAYMENTTOCONTRACT, data)
            }
        },
        async [Action.DELETEPAYMENT]({}, payment) {
            await paymentsService.delete(payment.id)
            // commit(Mutation.REMOVEPAYMENT, payment)
        },
        async [Action.UPDATEPAYMENT]({}, payment) {
            await paymentsService.update(payment)
        },
        async [Action.ADDCOMMENT]({}, comment) {
            const { status, data } = await commentsService.create(comment)
            if(status === 201) {
                return data
                // commit(Mutation.ADDCOMMENT, data)
            }
        },
        // async [Action.GETCONTRACTS]({ commit }) {
        //     commit(Mutation.SETLOADER, true)
        //     const { data } = await contractsService.getWithRelated()
        //     commit(Mutation.SETCONTRACTS, data)
        //     commit(Mutation.SETLOADER, false)
        // },
        // [Action.SETACTIVECONTRACT]({ commit }, contract) {
        //     commit(Mutation.SETACTIVECONTRACT, contract)
        // },
        // [Action.UNSETACTIVECONTRACT]({ commit }) {
        //     commit(Mutation.UNSETACTIVECONTRACT)
        // },
        // async [Action.SETMODE]({ commit }, mode) {
        //     commit(Mutation.SETMODE, mode)
        // },
        // async [Action.SETTOTALCONTRACTS]({ commit }, count) {
        //     commit(Mutation.SETTOTALCONTRACTS, count)
        // },
        // async [Action.SETLOADER]({ commit }, loading) {
        //     commit(Mutation.SETLOADER, loading)
        // },
    },
    mutations: {
        [Mutation.SETCONTRACTS](state, contracts) {
            state.contracts = contracts
        },
        [Mutation.SETCONTRACTCOUNT](state, count) {
            state.contractCount = count
        },
        [Mutation.SETCONTRACTMETA](state, meta) {
            state.contractMeta = meta
        },
        [Mutation.ADDCONTRACT](state, contract) {
            state.contracts.unshift(contract)
            state.contracts.map(x => contractsHelper.CalculatePercentPaid(x))
        },
        [Mutation.REMOVEPAYMENT](state, payment) {
            var contract = state.contracts.find(x => x.id === payment.contractId)
            contract.payments = contract.payments.filter(p => p.id != payment.id)
            state.contracts.map(x => contractsHelper.CalculatePercentPaid(x))
        },
        [Mutation.SETQUERIES](state, queries) {
            state.queries = queries
        },
        [Mutation.SETQUERYMETA](state, meta) {
            state.queryMeta = meta
        },
        // [Mutation.SETACTIVECONTRACT](state, contract) {
        //     state.activeContract = contract
        // },
        // [Mutation.UNSETACTIVECONTRACT](state) {
        //     state.activeContract = {}
        // },
        // [Mutation.SETLOADER](state, loading) {
        //     state.loading = loading
        // },
        // [Mutation.SETMODE](state, mode) {
        //     state.mode = mode
        // },        
        // [Mutation.UPDATECONTRACT](state, contract) {
        //     const index = state.contracts.map(c => c.id).indexOf(contract.id)  
        //     Vue.set(state.contracts, index, contract)
        //     state.contracts.map(x => CalculatePercentPaid(x))
        // },
        // [Mutation.ADDPAYMENTTOACTIVECONTRACT](state, payment) {
        //     state.activeContract.payments.push(payment)
        // },
        // [Mutation.ADDPAYMENTTOCONTRACT](state, payment) {
        //     var contract = state.contracts.find(x => x.id === payment.contractId)
        //     contract.payments.push(payment)
        // },
        // [Mutation.ADDCOMMENT](state, comment) {
        //     var contract = state.contracts.find(x => x.id === comment.contractId)
        //     contract.comments.unshift(comment)
        // },
    },
    getters: {
        contracts: state => {
            return state.contracts
        },
        contractMeta: state => {
            return state.contractMeta
        },
        queries: state => {
            return state.queries
        },
        queryMeta: state => {
            return state.queryMeta
        },
    }
}