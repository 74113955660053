<template>
    <div class="relative">
        <ConfirmDialog></ConfirmDialog>

        <Header>Upiti</Header>

        <div class="actions p-d-flex p-jc-between p-p-2">
            <SearchFilter :filter="filter" placeholder="Ime jahte, šifra upita, email..." @search="filterSearch" @reset="reset" />
            <router-link to='/queries/add'>
                <Button icon="pi pi-plus" label="Dodaj novi upit" class="p-button-sm" />
            </router-link>
        </div>
        
        <DataTable
            scrollHeight="flex" 
            :value="queries"
            :lazy="true"
            :rows="params.rows"
            :paginator="true"
            :autoLayout="true"
            ref="queries"
            :totalRecords="queryMeta.totalCount"
            :loading="loading"
            class="p-datatable-sm"
            selectionMode="single"
            dataKey="id"
            @page="onPage($event)"
            @sort="onSort($event)"
            @row-select="onRowSelect"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="Prikaz {first} do {last} od {totalRecords} upita"
            @row-unselect="onRowUnselect">
            <template #empty>
                <div class="p-p-3">
                    Nije pronađen nijedan upit.
                </div>
            </template>
            <Column header="Datum upita">
                <template #body="slotProps">
                    <display-date :date="slotProps.data.dateSigned" />
                </template>
            </Column>
            <Column header="Datum od">
                <template #body="slotProps">
                    <display-date :date="slotProps.data.dateFrom" />
                </template>
            </Column>
            <Column header="Datum do">
                <template #body="slotProps">
                    <display-date :date="slotProps.data.dateTo" />
                </template>
            </Column>
            <Column field="yacht" header="Ime broda/jahte"></Column>
            <Column field="code" header="Šifra"></Column>
            <Column header="Cijena">
                <template #body="slotProps">
                    {{ slotProps.data.price }} €
                </template>
            </Column>
            <Column headerStyle="width: 8em" bodyStyle="text-align: center">
                <template #body="slotProps">
                    <Button icon="pi pi-check-circle" class="p-button-secondary p-button-text" @click="confirmItem(slotProps.data)" v-tooltip.left="'Kreiraj ugovor'" />
                    <Button icon="pi pi-trash" class="p-button-secondary p-button-text" @click="deleteItem(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import DisplayDate from '../common/DisplayDate.vue'
import SearchFilter from '@/components/common/Filter'
import Header from '@/components/common/Header'
import { parse, format } from 'date-fns'
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
export default {   
    components: {
        Header,
        SearchFilter,
        DisplayDate
    },
    data() {
        return {
            loading: true,
            filter: {},
            params: {
                first: 0,
                page: 1,
                rows: 20,                
                sortField: null,
                sortOrder: null,
                filters: null
            }
        }
    },
    computed: {
        ...mapGetters({
            queries: 'queries',
            queryMeta: 'queryMeta',
        })
    }, 
    async created() {
        await this.search()
    },
    methods: {
        parse: parse,
        format: format,
        ...mapActions({
            getQueries: 'GETQUERIES',          
            confirmContract: 'CONFIRMCONTRACT',
            deleteContract: "DELETECONTRACT"
        }),
        onRowSelect(event) {
            router.push({ path: `/queries/${event.data.id}` })
        },
        onRowUnselect(event) {
            console.log(event)
        },
        async deleteItem(query) {          
            this.$confirm.require({
                message: 'Sigurno želite obrisati upit?',
                header: 'Brisanje upita',
                icon: 'pi pi-exclamation-triangle',
                accept: async () => {
                    this.loading = true
                    await this.deleteContract(query)
                    await this.getQueries({ params: this.params, filter: this.filter })
                    this.loading = false
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            })
        },   
        async confirmItem(contract) {          
            this.$confirm.require({
                message: 'Sigurno želite kreirati ugovor?',
                header: 'Kreiranje ugovora',
                icon: 'pi pi-check-circle',
                accept: async () => {
                    this.loading = true
                    await this.confirmContract(contract)   
                    await this.getQueries({ params: this.params, filter: this.filter })
                    this.loading = false
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            })
        },
        async filterSearch() {
            await this.search()
        },
        async reset() {
            this.filter = {}
            await this.search()
        },
        async search() {
            this.loading = true
            await this.getQueries({ params: this.params, filter: this.filter })
            this.loading = false
        },
        async onPage(event) {
            this.params = event
            this.params.page = event.page + 1
            await this.search()
        },
    },
}
</script>