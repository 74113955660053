<template>
    <div v-if="showWarning">
        <i class="pi pi-exclamation-triangle warning" style="fontSize: 1.2rem" @click.stop="toggle" aria:haspopup="true" aria-controls="overlay_panel"></i>
        <OverlayPanel ref="op" appendTo="body" id="overlay_panel" :showCloseIcon="true">
            <!-- <Tag v-if="showTag" :value="message" icon="pi pi-exclamation-triangle" severity="warning"></Tag> -->
            <Message v-if="messageBeforeEnd" :severity="messageBeforeEnd.severity" :closable="false">{{ messageBeforeEnd.message }}</Message>
            <Message v-if="messageBeforeInsurance" :severity="messageBeforeInsurance.severity" :closable="false">{{ messageBeforeInsurance.message }}</Message>
        </OverlayPanel>
    </div>
</template>

<script>
import { parse, differenceInDays } from 'date-fns'
export default {
    props: {
        contract: {
            type: Object,
            required: true,
        }
    },
    computed: {
        showWarning: function() {
            if(this.messageBeforeEnd || this.messageBeforeInsurance)
                return true

            return false
        },
        daysToInsurance: function() {
            return differenceInDays(parse(this.contract.dateTo, 'yyyy-MM-dd 00:00:00', new Date()), Date.now())
        },
        messageBeforeEnd: function() {

            if(!this.contract.dateTo)
                return null

            var daysToEnd = differenceInDays(parse(this.contract.dateTo, 'yyyy-MM-dd 00:00:00', new Date()), Date.now())
            if(daysToEnd < 0) {
                return { message: `Ugovor je istekao prije ${Math.abs(daysToEnd)} dan/a`, severity: 'error' }
            } else if(daysToEnd == 0) {
                return { message: `Ugovor ističe danas`, severity: 'error' }
            } else if(daysToEnd <= 7) {
                return { message: `${daysToEnd} dan/a do isteka ugovora`, severity: 'error' }
            } else if(daysToEnd <= 14) {
                return { message: `${daysToEnd} dan/a do isteka ugovora`, severity: 'warn' }
            } else {
                return null
            }
        },
        messageBeforeInsurance: function() {

            if(!this.contract.dateInsurance)
                return null

            var daysToInsurance = differenceInDays(parse(this.contract.dateInsurance, 'yyyy-MM-dd 00:00:00', new Date()), Date.now())
            if(daysToInsurance < 0) {
                return { message: `Osiguranje je isteklo prije ${Math.abs(daysToInsurance)} dan/a`, severity: 'error' }
            } else if(daysToInsurance == 0) {
                return { message: `Osiguranje ističe danas`, severity: 'error' }
            } else if(daysToInsurance <= 7) {
                return { message: `${daysToInsurance} dan/a do isteka osiguranja`, severity: 'error' }
            } else if(daysToInsurance <= 14) {
                return { message: `${daysToInsurance} dan/a do isteka osiguranja`, severity: 'warn' }
            } else {
                return null
            }
        }
    },
    methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        },
    }
}
</script>