<template>
     <div class="p-d-flex p-ai-center">
        <ProgressBar :showValue="false" :value="parseFloat(data.percentPaid)" style="height: .2em; width:80px;" class="p-mr-3" />
        {{ data.percentPaid }}%
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    }
}
</script>