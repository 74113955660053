<template>
    <div class="filter p-d-flex p-ai-center">
        <Dropdown v-model="filter.user" :options="users" optionLabel="name" optionValue="id" placeholder="Korisnik" class="p-mr-2" />
        <InputText style="width:300px;" type="text" v-model.trim="filter.all" @keydown.enter="$emit('search', filter)" class="p-mr-2 p-inputtext-sm" placeholder="Poruka/Ime/šifra polaznika" />

        <div class="p-buttonset">
            <Button label="Pretraži" icon="pi pi-search" class="p-button-sm" @click="$emit('search', filter)" />
            <Button label="Poništi" icon="pi pi-times-circle" class="p-button-secondary p-button-sm" @click="$emit('reset')" />
        </div>
    </div>
</template>

<script>
import auditService from '../../services/audit.service'
export default {
    props: {
        filter: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            actions: [
                { icon: 'pi pi-circle-off', id: null },
                { icon: 'pi pi-plus', id: 1 },
                { icon: 'pi pi-pencil', id: 2 },
                { icon: 'pi pi-trash', id: 3 },
                { icon: 'pi pi-book', id: 4 },
            ],
            users: []
        }
    },
    async created() {
        this.users = await auditService.getUsers()
    }
}
</script>