<template>
    <div>
        <p class="form--small-label p-my-4 p-mx-3">Podaci o ugovoru</p>
        <div class="p-fluid p-formgrid p-grid p-p-2 border-bottom">
            <div class="p-field p-col-12 p-md-3" :class="{ 'form-group--error': $v.contract.dateFrom.$error }">
                <label for="dateFrom">Datum od</label>
                <custom-date-picker :date.sync="contract.dateFrom" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.dateFrom.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-3" :class="{ 'form-group--error': $v.contract.dateTo.$error }">
                <label for="dateTo">Datum do</label>
                <custom-date-picker :date.sync="contract.dateTo" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.dateTo.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-3" :class="{ 'form-group--error': $v.contract.dateArrival.$error }">
                <label for="dateArrival">Datum uplovljavanja</label>
                <custom-date-picker :date.sync="contract.dateArrival" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.dateArrival.required">Polje je obavezno</small>
            </div>            

            <div class="p-field p-col-12 p-md-3" :class="{ 'form-group--error': $v.contract.dateSigned.$error }">
                <label for="dateSigned">Datum potpisivanja ugovora</label>
                <custom-date-picker :date.sync="contract.dateSigned" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.dateSigned.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-3" :class="{ 'form-group--error': $v.contract.price.$error }">
                <label for="price">Cijena</label>
                <InputNumber id="price" showButtons v-model="contract.price" mode="currency" currency="EUR" locale="de-DE" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.price.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-3" :class="{ 'form-group--error': $v.contract.discount.$error }">
                <label for="discount">Popust</label>
                <InputNumber id="discount" showButtons v-model="contract.discount" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" suffix="%" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.discount.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-3" :class="{ 'form-group--error': $v.contract.code.$error }">
                <label for="code">Šifra ugovora</label>
                <InputText id="code" type="text" v-model.trim="contract.code" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.code.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-3" :class="{ 'form-group--error': $v.contract.dateInsurance.$error }">
                <label for="dateInsurance">Datum isteka osiguranja</label>
                <custom-date-picker :date.sync="contract.dateInsurance" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.dateInsurance.required">Polje je obavezno</small>
            </div>
        </div>
        
        <p class="form--small-label p-my-4 p-mx-3">Podaci o plovilu</p>
        <div class="p-fluid p-formgrid p-grid p-p-2 border-bottom">
            <div class="p-field p-col-12 p-md-8" :class="{ 'form-group--error': $v.contract.yacht.$error }">
                <label for="yacht">Ime plovila</label>
                <InputText id="yacht" type="text" v-model.trim="contract.yacht" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.yacht.required">Polje je obavezno</small>
            </div> 

            <div class="p-field p-col-12 p-md-4" :class="{ 'form-group--error': $v.contract.registration.$error }">
                <label for="registration">Oznaka</label>
                <InputText id="registration" type="text" v-model.trim="contract.registration" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.registration.required">Polje je obavezno</small>
            </div> 

            <div class="p-field p-col-12 p-md-4" :class="{ 'form-group--error': $v.contract.length.$error }">
                <label for="length">Duljina</label>
                <InputNumber id="length" showButtons v-model="contract.length" suffix=" m" :disabled="!edit" mode="decimal" :minFractionDigits="2" :maxFracionDigits="2" />
                <small class="p-invalid" v-if="!$v.contract.length.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-4" :class="{ 'form-group--error': $v.contract.width.$error }">
                <label for="width">Širina</label>
                <InputNumber id="width" showButtons v-model="contract.width" suffix=" m" :disabled="!edit" mode="decimal" :minFractionDigits="2" :maxFracionDigits="2" />
                <small class="p-invalid" v-if="!$v.contract.width.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-4" :class="{ 'form-group--error': $v.contract.type.$error }">
                <label for="type">Tip plovila</label>
                <!-- <InputText id="type" type="text" v-model="contract.type" :disabled="!edit" /> -->
                <Dropdown v-model="contract.type" :options="boatTypes" :filter="true" optionLabel="name" optionValue="value" placeholder="Odaberi tip plovila" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.type.required">Polje je obavezno</small>
            </div>
        </div>

        <p class="form--small-label p-my-4 p-mx-3">Podaci o vlasniku</p>
        <div class="p-fluid p-formgrid p-grid p-p-2 border-bottom">
            <div class="p-field p-col-12 p-md-8" :class="{ 'form-group--error': $v.contract.owner.$error }">
                <label for="owner">Ime vlasnika</label>
                <InputText id="owner" v-model.trim="contract.owner" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.owner.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-4" :class="{ 'form-group--error': $v.contract.ownerOib.$error }">
                <label for="ownerOib">OIB</label>
                <InputText id="ownerOib" v-model.trim="contract.ownerOib" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.ownerOib.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-6" :class="{ 'form-group--error': $v.contract.ownerAddress.$error }">
                <label for="ownerAddress">Adresa</label>
                <InputText id="ownerAddress" v-model.trim="contract.ownerAddress" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.ownerAddress.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-6" :class="{ 'form-group--error': $v.contract.ownerContact.$error }">
                <label for="ownerContact">Kontakt</label>
                <InputText id="ownerContact" v-model.trim="contract.ownerContact" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.ownerContact.required">Polje je obavezno</small>
            </div>
        </div>

        <p class="form--small-label p-my-4 p-mx-3">Podaci o ovlaštenom predstavniku vlasnika</p>
        <div class="p-fluid p-formgrid p-grid p-p-2 border-bottom">
            <div class="p-field p-col-12 p-md-8" :class="{ 'form-group--error': $v.contract.user.$error }">
                <label for="user">Ime predstavnika vlasnika</label>
                <InputText id="user" v-model.trim="contract.user" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.user.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-4" :class="{ 'form-group--error': $v.contract.userOib.$error }">
                <label for="userOib">OIB</label>
                <InputText id="userOib" v-model.trim="contract.userOib" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.userOib.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-6" :class="{ 'form-group--error': $v.contract.userAddress.$error }">
                <label for="userAddress">Adresa</label>
                <InputText id="userAddress" v-model.trim="contract.userAddress" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.userAddress.required">Polje je obavezno</small>
            </div>

            <div class="p-field p-col-12 p-md-6" :class="{ 'form-group--error': $v.contract.userContact.$error }">
                <label for="userContact">Kontakt</label>
                <InputText id="userContact" v-model.trim="contract.userContact" :disabled="!edit" />
                <small class="p-invalid" v-if="!$v.contract.userContact.required">Polje je obavezno</small>
            </div>
        </div>

        <div class="p-fluid p-formgrid p-grid p-p-2">
            <div class="p-field p-col-12">
                <label for="note">Napomena</label>
                <Textarea id="note" v-model="contract.note" rows="4" :disabled="!edit" />
            </div>
        </div>
    </div>
</template>

<script>
import CustomDatePicker from '../common/CustomDatePicker.vue'
import router from '@/router'
import { mapActions } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
export default {
    props: {
        contract: {
            type: Object,
            required: true,
        },
        edit: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: null,
        }
    },
    components: {
        CustomDatePicker
    },
    data() {
        return {
            unchangedContract: {},
            hr: {
                firstDayOfWeek: 1,
                dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                dayNamesMin: ["Ne","Mo","Tu","We","Th","Fr","Sa"],
                monthNames: [ "Siječanj","Veljača","Ožujak","Travanj","Svibanj","Lipanj","Srpanj","Kolovoz","Rujan","Listopad","Studeni","Prosinac" ],
                monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
                today: 'Danas',
                clear: 'Obriši',
                dateFormat: 'mm/dd/yy',
                weekHeader: 'Wk'
            },
            boatTypes: [
                { name: 'Jedrilica', value: 1 },
                { name: 'Katamaran', value: 2 },
                { name: 'Motorni katamaran', value: 3 },
                { name: 'Motorna jahta', value: 4 },
                { name: 'Luksuzna motorna jahta', value: 5 },
                { name: 'Motorni brod', value: 6 },
                { name: 'Jedrenjak', value: 7 },
                { name: 'Gulet', value: 8 },
                { name: 'Gumenjak', value: 9 },
            ],
            date: null
        }
    },    
    validations: {
        contract: {
            dateFrom: { },
            dateTo: { },
            dateArrival: { },
            dateInsurance: { },
            price: { required },
            discount: { required },
            code: { },
            dateSigned: { },
            yacht: { 
                required: requiredIf(prop => prop.registration === '' || prop.registration === undefined),
            },
            registration: { 
                required: requiredIf(prop => prop.yacht === '' || prop.yacht === undefined),
            },
            length: { required },
            width: { },
            type: { },
            owner: { },       
            ownerOib: { },     
            ownerAddress: { },
            ownerContact: { },            
            user: {
                // required: requiredIf(prop => prop.userContact === '' || prop.userContact === undefined),
            },
            userOib: { },
            userAddress: { },
            userContact: { 
                // required: requiredIf(prop => prop.user === '' || prop.user === undefined),
            }
        } 
    },
    methods: {
        async submit() {
            'id' in this.contract ? await this.update() : await this.save()
        },
        editContract() {
            this.unchangedContract = { ...this.contract }
            this.$emit('update:edit', true)
        },
        async save() {
            this.$v.$touch()
            if (this.$v.$invalid) return

            let contract = await this.addContract(this.contract)
            router.push({ path: `/contracts/${contract.id}` })
        },
        async update() {
            this.$v.$touch()
            if (this.$v.$invalid) return

            this.$emit("update:contract", await this.updateContract(this.contract))
            this.$emit("update:edit", false)
        },
        async toggleWarning() {
            this.contract.warning = this.contract.warning === 1 ? 0 : 1; 
            this.$emit("update:contract", await this.updateContract(this.contract))
        },
        cancel() {
            this.$emit("update:contract", this.unchangedContract)
            this.$emit("update:edit", false)
        },
        ...mapActions({
            addContract: 'ADDCONTRACT',
            updateContract: 'UPDATECONTRACT',
        }),
    },
}
</script>