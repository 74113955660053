<template>
    <div v-if="date">
        {{ format(parse(date, 'yyyy-MM-dd 00:00:00', new Date()), 'dd.MM.yyyy') }}
    </div>
</template>

<script>
import { parse, format } from 'date-fns'
export default {
    props: {
        date: {
            type: String
        }
    },
    methods: {
        parse: parse,
        format: format
    }
}
</script>