<template>
    <div class="p-d-flex p-ai-center">
        <i class="pi pi-credit-card p-mr-2 p-text-gray"></i>
        {{ payments.length }}
    </div>
</template>

<script>
export default {
    props: {
        payments: {
            type: Array
        }
    }
}
</script>