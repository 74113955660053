<template>
    <div class="contract--layout p-d-flex p-flex-column">
        <div class="actions p-d-flex p-jc-between p-p-2">
            <Button label="Natrag" icon="pi pi-arrow-left" class="p-button-text p-button-sm" @click="back()" />
            <Button label="Spremi" icon="pi pi-check" class="p-button-sm" @click="save()" />
        </div>

        <div class="contract--data p-d-flex">
            <div class="contract--form">                
                <Header>
                    Novi ugovor
                </Header>
                <Form :contract="contract" :edit="true" ref="form" />
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header'
import router from '@/router'
import { mapActions } from 'vuex'
import Form from './Form'
// import contractsService from '../../services/contracts.service'
export default {
    data() {
        return {
            contract: {
                // dateFrom: '2021-06-14 00:00:00',
                // dateTo: '2021-06-15 00:00:00',
                // dateArrival: '2021-06-16 00:00:00',
                // dateSigned: '2021-06-17 00:00:00',
                // dateInsurance: '2021-06-30 00:00:00',
                // price: 2000,
                // code: Math.random().toString(36).substring(7),
                // yacht: Math.random().toString(36).substring(7),
                // registration: Math.random().toString(36).substring(7),
                // length: 2,
                // width: 2,
                // type: "Sailboat",
                // owner: Math.random().toString(36).substring(7),
                // ownerOib: Math.random().toString(36).substring(7),
                // ownerAddress: Math.random().toString(36).substring(7),
                // ownerContact: Math.random().toString(36).substring(7),
                // user: Math.random().toString(36).substring(7),
                // userOib: Math.random().toString(36).substring(7),
                // userAddress: Math.random().toString(36).substring(7),
                // userContact: Math.random().toString(36).substring(7),
            },
            loading: false
        }
    },
    components: {
        Header,
        Form
    },
    computed: {
        controller() {
            if(this.$route.fullPath.includes("contracts"))
                return "contracts"

            return "queries"
        }
    },
    methods: {
        back() {
            router.push({ path: `/${this.controller}` })
        },
        async save() {
            this.$refs.form.$v.$touch()
            if (this.$refs.form.$v.$invalid) return

            if(this.controller === "contracts")
                this.contract.confirmed = 1

            let contract = await this.addContract(this.contract)
            router.push({ path: `/${this.controller}/${contract.id}` })

            // const { data } = await contractsService.isCodeUnique(this.contract)
            // if(data === true) {
            //     let contract = await this.addContract(this.contract)
            //     router.push({ path: `/queries/${contract.id}` })
            // } else {                
            //     this.$toast.add({severity:'warn', summary: 'Šifra zauzeta', detail:'Šifra polaznika je zauzeta.', life: 3000});
            // }
        },
        ...mapActions({
            addContract: 'ADDCONTRACT',
        }),
    },
}
</script>