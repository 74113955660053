<template>
    <div class="comments">
        <Header>Komentari</Header>
        <div class="comments-inner">
            <InputText class="comments-input p-mb-5" type="text" v-model.trim="model.content" @keyup.enter="saveComment()" v-if="mode != 'details'" placeholder="Unesi komentar" />
            <div class="p-p-2">
                <Comment v-for="comment in contract.comments" :key="comment.id" :comment="comment" :user="auth.user.username" />
            </div>
        </div>
    </div>
</template>

<script>
import Header from '@/components/common/Header'
import authService from '@/services/auth.service'
import Comment from './Comment.vue'
import { mapActions } from 'vuex'
export default {
    props: {
        contract: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            auth: authService,
            model: {},
        }
    },
    components: {
        Comment,
        Header
    },
    methods: {
        ...mapActions({
            addComment: 'ADDCOMMENT'
        }),
        async saveComment() {
            if(this.model.content) {
                this.model.contractId = this.contract.id
                this.model.username = this.auth.user.username
                let comment = await this.addComment(this.model)
                this.model = {}
                this.$emit('add-comment', comment)
            }
        }
    }
}
</script>