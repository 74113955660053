import httpClient from "./http.service"

const dailyService = {
    get(params) {
        return httpClient.get(`dailies?page=${params.params.page}`, {
            params: {
                params: params
            }
        })
    },
    // getById(dailyId) {
    //     return httpClient.get(`dailys/${dailyId}`)
    // },
    // create(daily) {
    //     return httpClient.post('dailys', daily)
    // },
    // update(daily) {
    //     // daily.price = Number(contract.price)
    //     return httpClient.put(`dailys/${daily.id}`, daily)
    // },
    // delete(dailyId) {
    //     return httpClient.delete(`dailys/${dailyId}`)
    // },
}

export default dailyService;